import {willPeopleHelpers} from "./willPeopleHelpers";
import {willRelationshipHelpers} from "./willRelationshipHelpers";
import {willProfessionalOptions} from "./willProfessionalOptions";
import { willOptions } from "../../../question/options/willOptions";

export const willFuneralHelpers = {
    // required a details computed property: only to be used in root level Funeral file
    mixins: [willPeopleHelpers, willRelationshipHelpers, willProfessionalOptions, willOptions],
    mounted() {
        let attributeAdded = false
        // for old created will insert new fields into data
        if (!('typeOption' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'typeOption', null)
            attributeAdded = true
        }
        if (!('donorOption' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'donorOption', null)
            attributeAdded = true
        }
        if (!('customWishesYN' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, ' customWishesYN', null)
            attributeAdded = true
        }
        if (!('customWishesOption' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'customWishesOption', null)
            attributeAdded = true
        }
        if (!('styleOptions' in this.details)) {
            // set new fields for old created wills
            this.$set(this.details, 'styleOptions', [])
            attributeAdded = true
        }
        if (attributeAdded) {
            this.save()
        }
    },
    watch: {
        'form.data.funeral.details.typeOption': {
            deep: false,
            immediate: false,
            handler(value) {
                this.setFuneralOption(value)
            }
        },
        'form.data.funeral.details.donorOption': {
            deep: false,
            immediate: false,
            handler(value) {
                this.setDonorOption(value)
            }
        },
        'form.data.funeral.details.customWishesYN': {
            deep: false,
            immediate: false,
            handler(value) {
                this.setCustomWishesYN(value)
            }
        },
        'form.data.funeral.details.customWishesOption': {
            deep: false,
            immediate: false,
            handler(value) {
                this.setCustomWishesOption(value)
            }
        },
    },
    computed: {
        details() {
            return this.form.data.funeral.details
        },
        data() {
            return this.form.data.funeral.details
        },
        storePeople() {
            return this.$store.getters.people
        }
    },
    data() {
        return {
            options: {
                burial: {
                    name: 'Burial',
                    type: 'funeral',
                    subType: 'burial',
                    text: 'I wish my body to be buried.'
                },
                cremation: {
                    name: 'Cremation',
                    type: 'funeral',
                    subType: 'cremation',
                    text: 'I wish my body to be cremated.'
                },
                research: {
                    name: 'Donor Research',
                    type: 'funeral',
                    subType: 'research',
                    text:
                        'If practicable I request that my body or any part thereof be used for the purposes of ' +
                        'medical education and research.'
                },
                researchTherapy: {
                    name: 'Donor Research & Therapy',
                    type: 'funeral',
                    subType: 'researchAndTherapy',
                    text:
                        'If practical I request that my body or any part thereof be used for therapeutic purposes ' +
                        'or for the purposes of medical education and research.'
                },
                therapy: {
                    name: 'Donor Therapy',
                    type: 'funeral',
                    subType: 'therapy',
                    text:
                        'If practicable I request that my body or any part thereof be used for therapeutic purposes.'
                },
                donorNone: {
                    name: 'No Donor Research or Therapy',
                    type: 'funeral',
                    subType: 'noResearchAndTherapy',
                    text:
                        'Neither my body or any part thereof is to be used for the purposes of medical education, ' +
                        'research or therapeutic purposes.'
                },
                customWishes: {
                    name: 'Custom Wishes',
                    type: 'funeral',
                    subType: 'custom',
                    text: ''
                },
                celebration: {
                    name: 'Celebration',
                    type: 'funeral',
                    subType: 'celebration',
                    text: 'It is my wish that my funeral be conducted as a celebration of my life.'
                },
                simple: {
                    name: 'Simple',
                    type: 'funeral',
                    subType: 'simple',
                    text: 'I would prefer that my funeral be both simple and respectful.'
                },
                green: {
                    name: 'Green',
                    type: 'funeral',
                    subType: 'green',
                    text: 'Any arrangements for the disposal of my body should be as green and eco friendly as possible.'
                },
                grand: {
                    name: 'Grand Send Off',
                    type: 'funeral',
                    subType: 'grand',
                    text: 'The occasion should be a grand send off.'
                },
                nonReligious: {
                    name: 'Non Religious',
                    type: 'funeral',
                    subType: 'nonReligious',
                    text: 'My funeral should not be religious in nature.'
                },
            }
        }
    },
    methods: {
        setFuneralOption(value) {
            // find wishes already selected
            // remove already selected wishes
            let burialIndex = this.details.requests.findIndex(wish => wish && wish.subType === 'burial')
            if (burialIndex >= 0) {
                this.form.data.funeral.details.requests.splice(burialIndex, 1)
            }
            let cremationIndex = this.details.requests.findIndex(wish => wish && wish.subType === 'cremation')
            if (cremationIndex >= 0) {
                this.form.data.funeral.details.requests.splice(cremationIndex, 1)
            }
            // add selected value (wish) to requests
            if (value in this.options) this.form.data.funeral.details.requests.push(this.options[value])
            this.save()
        },
        setDonorOption(value) {
            // find wishes already selected
            // remove already selected wishes
            let noIndex = this.details.requests.findIndex(wish => wish.subType === 'noResearchAndTherapy')
            if (noIndex >= 0) {
                this.form.data.funeral.details.requests.splice(noIndex, 1)
            }
            let therapyIndex = this.details.requests.findIndex(wish => wish.subType === 'therapy')
            if (therapyIndex >= 0) {
                this.form.data.funeral.details.requests.splice(therapyIndex, 1)
            }
            let researchAndTherapyIndex = this.details.requests.findIndex(wish => wish.subType === 'researchAndTherapy')
            if (researchAndTherapyIndex >= 0) {
                this.form.data.funeral.details.requests.splice(researchAndTherapyIndex, 1)
            }
            let researchIndex = this.details.requests.findIndex(wish => wish.subType === 'research')
            if (researchIndex >= 0) {
                this.form.data.funeral.details.requests.splice(researchIndex, 1)
            }
            // add selected value (wish) to requests
            if (value in this.options) this.form.data.funeral.details.requests.push(this.options[value])
            this.save()
        },
        setCustomWishesYN (value) {
          // remove custom wish if no is selected
            if (value === false && this.details.customWishesOption ) {
                this.form.data.funeral.details.customWishesOption = ''
            }
        },
        setCustomWishesOption(value) {
            // remove custom wish
            let customIndex = this.details.requests.findIndex(wish => wish.subType === 'custom')
            if (customIndex >= 0) {
                this.form.data.funeral.details.requests.splice(customIndex, 1)
            }
            // add custom wish with modified wish text
            if (value !== '') { // could be blank text field
                let wish = this.clone(this.options.customWishes)
                wish.text = value
                this.form.data.funeral.details.requests.push(wish)
            }
            this.save()
        },
        setStyleOptions(value) {
            // remove all funeral style options
            for (let x in this.funeralStyleOptions) {
                let index = this.details.requests.findIndex(wish => wish.subType === this.funeralStyleOptions[x].value)
                if (index >= 0) {
                    this.form.data.funeral.details.requests.splice(index, 1)
                }
            }
            // add all selected funeral style options
            for (let y in value) {
                if (value[y] in this.options) {
                    let wish = this.clone(this.options[value[y]])
                    this.form.data.funeral.details.requests.push(wish)
                }
            }
            this.save()
        }
    }
}
