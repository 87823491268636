<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="data !== null">
    <InputRadio
        v-model="data"
        :options="funeralTypeOptions"
        :db="db"
        :args="args"
        :skipped="skipped"
        class="radio-options-wide"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import {willRelationshipHelpers} from "../../../../fieldsets/will/helpers/willRelationshipHelpers";
import {willPeopleHelpers} from "../../../../fieldsets/will/helpers/willPeopleHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {willOptions} from "../../../options/willOptions";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

import QuestionBase from "../../../QuestionBase";
import InputRadio from "../../../inputs/InputRadio";


export default {
  name: 'FuneralType',
  mixins: [questionLoadHelpers, willPeopleHelpers, willRelationshipHelpers, personHelpers, textHelpers, willOptions],
  components: {
    InputRadio,
    QuestionBase
  },
  props: {
    value: {
      type: [String, Boolean],
      required: false
    },
    details: {
      type: Object,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'Would you prefer to be buried or cremated?',
        subTitle: 'Documenting a preference in your Will, may make your executors job easier.',
        tip: null,
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: 'funeralType',
        formPath: 'data.funeral.details.typeOption',
        jsonSaveField: 'typeOption'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
