<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="![null, ''].includes(data)">
    <InputTextArea
        v-model="data"
        :db="db"
        :args="args"
        :skipped="skipped"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import {willRelationshipHelpers} from "../../../../fieldsets/will/helpers/willRelationshipHelpers";
import {willPeopleHelpers} from "../../../../fieldsets/will/helpers/willPeopleHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {willOptions} from "../../../options/willOptions";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";

import QuestionBase from "../../../QuestionBase";
import InputTextArea from "../../../inputs/InputTextArea";


export default {
  name: 'CustomWishes',
  mixins: [questionLoadHelpers, willPeopleHelpers, willRelationshipHelpers, personHelpers, textHelpers, willOptions],
  components: {
    InputTextArea,
    QuestionBase
  },
  props: {
    value: {
      type: [String, Boolean],
      required: false
    },
    details: {
      type: Object,
      required: false
    }
  },
  computed: {
    data: {
      set(value) {
        this.$emit('input', value)
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'What are your wishes?',
        subTitle: null,
        tip: null,
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: 'customWishesOption',
        formPath: 'data.funeral.details.customWishesOption',
        jsonSaveField: 'customWishesOption'
      },
      args: {
        placeholder: 'e.g. it is my wish that my ashes are scattered at sea.',
        label: null,
        rows: 4,
        maxRows: 4,
        skippable: false,
        required: true,
        inputClass: 'field-66'
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
