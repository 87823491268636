<template>
  <div class="fieldset">
    <h3 class="fieldset-heading">Donor</h3>

    <DonorType v-model="form.data.funeral.details.donorOption" />


  </div>
</template>

<script>
import { willPeopleHelpers} from "@/components/common/questionnaires/fieldsets/will/helpers/willPeopleHelpers";
import { fieldsetHelpers } from "@/components/common/questionnaires/fieldsets/fieldsetHelpers";
import { willFuneralHelpers } from "@/components/common/questionnaires/fieldsets/will/helpers/willFuneralHelpers";
import {clone} from "@/mixins/clone";
import {saveHelpers} from "@/views/questionnaires/saveHelpers";

import DonorType from "@/components/common/questionnaires/question/questions/will/funeral/DonorType";

export default {
  name: 'FuneralDonor',
  mixins: [
    fieldsetHelpers,
    willPeopleHelpers,
    clone,
    saveHelpers,
    willFuneralHelpers
  ],
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  components: {
    DonorType
  },
  data() {
    return {
      label: 'Donor', // used for steps progress
      primaryOptions: [],
      secondaryOptions: [],
      backupOptions: [],
      db: {
        saveLocation: 'product_will_data',
        saveField: 'details',
        formPath: 'data.funeral.details'
      }
    }
  },
  computed: {
    form: {
      set(value) {
        this.$emit('input',
            value)
      },
      get() {
        return this.value
      }
    }
  }
};
</script>
