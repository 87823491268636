<template>
  <QuestionBase :question="question" :errors="[...serverErrors, ...jsErrors]" :valid="!!data.length">
    <InputRadioMultiple
        v-model="data"
        :options="funeralStyleOptions"
        :db="db"
        :args="args"
        :skipped="skipped"
        class="radio-options-wide"
        @skipped="skipped=$event"
        @serverErrors="serverErrors=$event"
        @jsErrors="jsErrors=$event"
    />
  </QuestionBase>
</template>

<script>
import {willRelationshipHelpers} from "../../../../fieldsets/will/helpers/willRelationshipHelpers";
import {willPeopleHelpers} from "../../../../fieldsets/will/helpers/willPeopleHelpers";
import {questionLoadHelpers} from "../../../helpers/questionLoadHelpers";
import {willOptions} from "../../../options/willOptions";
import {personHelpers} from "@/mixins/personHelpers";
import {textHelpers} from "@/mixins/textHelpers";
import { clone } from "@/mixins/clone";
import QuestionBase from "../../../QuestionBase";
import InputRadioMultiple from "../../../inputs/InputRadioMutiple";


export default {
  name: 'StyleType',
  mixins: [questionLoadHelpers, willPeopleHelpers, willRelationshipHelpers, personHelpers, textHelpers, willOptions, clone],
  components: {
    InputRadioMultiple,
    QuestionBase
  },
  props: {
    value: {
      type: [Array],
      required: false
    },
    details: {
      type: Object,
      required: false
    }
  },
  computed: {
    data: {
      set(newValue) {
        // if false in value then remove all other options
        let includesNoPreference = newValue.includes(false)
        if (includesNoPreference) {
          this.$emit('input', [false])
        }
        else {
          this.$emit('input', newValue)
        }
      },
      get() {
        return this.value
      }
    }
  },
  data() {
    return {
      question: {
        title: 'What type of funeral would you prefer?',
        subTitle: 'When your executors come to organise your funeral, information about your wishes can be invaluable. You can select multiple options.',
        tip: null,
      },
      db: {
        saveLocation: 'product_will_data',
        saveField: 'funeralStyleOptions',
        formPath: 'data.funeral.details.styleOptions',
        jsonSaveField: 'styleOptions'
      },
      args: {
        skippable: false,
        required: true,
      },
      serverErrors: [],
      jsErrors: [],
      skipped: false,
    }
  }
}
</script>
